import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import styled from 'styled-components';
import 'swiper/css';

SwiperCore.use([Pagination]);

const Slider: React.FC = (): React.ReactElement => {
  return (
    <SliderContainer>
      <Swiper
        modules={[Pagination]}
        pagination={false}
        spaceBetween={0}
        slidesPerView={1}
        loop={false}
      >
        <SliderSlide>
          <SliderImageWrapper>
            <SliderImage src="/assets/img/swiper/sportadium.png" alt="image" />
          </SliderImageWrapper>

          <SliderTextContainer>
            <SliderTitle>Sportadium</SliderTitle>
            <SliderText>похудеть реально с
              <SliderTextSpan>онлайн консультациями от профессионалов</SliderTextSpan>
            </SliderText>
          </SliderTextContainer>
        </SliderSlide>
      </Swiper>
    </SliderContainer>
  );
};

const SliderContainer = styled.div`
    display: flex;
    width: 100%;
    position: relative;

    .swiper {
        height: 100%;
        width: 100%;
        background: #020028;
    }

    .swiper-pagination {
        display: flex;
        position: absolute;
        top: 191px;
        right: 159px;
        z-index: 10;
    }

    .swiper-pagination-bullet {
        display: block;
        width: 8px !important;
        height: 8px !important;
        border: 1px solid #fff;
        border-radius: 50%;
        margin: 0 8px 0 0;
    }

    .swiper-pagination-bullet-active {
        background-color: #fff;
    }

    @media (max-width: 575px) {
    }

    @media (min-width: 576px) and (max-width: 767px) {
    }

    @media (min-width: 768px) and (max-width: 1199px) {
    }

    @media (min-width: 1200px) {
    }
`;

const SliderSlide = styled(SwiperSlide)``;

const SliderImageWrapper = styled.div`
    width: 284px;
    height: 204px;
    transition: width 0.3s ease-in-out;

    @media (min-width: 390px) and (max-width: 575px) {
        width: 324px;
        height: 244px;
    }

    @media (min-width: 576px) and (max-width: 767px) {
        width: 354px;
        height: 274px;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
    }

    @media (min-width: 1200px) {
    }
`;

const SliderImage = styled.img`
    width: 100%;
    height: auto;
    max-width: 100%;
    min-height: 100%;
    display: block;
    object-fit: cover;
`;

const SliderTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    position: absolute;
    top: 44px;
    left: 55%;
    transition: all 200ms ease;

    @media (min-width: 375px) {
        left: 50%;
    }
`;

export const SliderTitle = styled.h2`
    font-size: 17px;
    font-weight: 800;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin: 0 0 5px 0;
    transition: font-size 0.3s ease-in-out;

    @media (min-width: 390px) and (max-width: 575px) {
        font-size: 22px;
        margin: 0 0 18px 0;
    }

    @media (min-width: 576px) and (max-width: 767px) {
        font-size: 29px;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
    }

    @media (min-width: 1200px) {
    }
`;

const SliderText = styled.p`
    display: flex;
    flex-wrap: wrap;
    font-size: 11px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.05em;
    transition: font-size 0.3s ease-in-out;

    @media (min-width: 390px) and (max-width: 575px) {
        font-size: 14px;
    }

    @media (min-width: 576px) and (max-width: 767px) {
        font-size: 19px;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
    }

    @media (min-width: 1200px) {
    }
`;

const SliderTextSpan = styled.span`
    font-size: 11px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #13ffee;

    @media (min-width: 390px) and (max-width: 575px) {
        font-size: 14px;
    }

    @media (min-width: 576px) and (max-width: 767px) {
        font-size: 19px;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
    }

    @media (min-width: 1200px) {
    }
`;

export default Slider;
